<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="订单编号" prop="id">
                <a-input v-model="queryParam.id" placeholder="请输入订单编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="三方支付订单号" prop="outTradeNo">
                <a-input v-model="queryParam.outTradeNo" placeholder="请输入三方支付订单号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="订单状态" prop="orderStatus">
                <a-select placeholder="请选择订单状态" style="width: 100%" v-model="queryParam.orderStatus" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.OrderAdvertStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="审核状态" prop="orderStatus">
                  <a-select placeholder="请选择审核状态" style="width: 100%" v-model="queryParam.auditStatus" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.OrderCancelAuditStatusEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="支付类型" prop="payType">
                  <a-select placeholder="请选择支付类型" style="width: 100%" v-model="queryParam.payType" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.OrderPayTypeEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24">
                <a-form-item label="签约公司" prop="signName">
                  <a-input v-model="queryParam.signName" placeholder="签约公司" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="联系人" prop="contactName">
                  <a-input v-model="queryParam.contactName" placeholder="请输入联系人" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="联系电话" prop="contactPhone">
                  <a-input v-model="queryParam.contactPhone" placeholder="请输入联系电话" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="付款区间" prop="payAmountRange">
                  <a-input-number style="width: 45%" :maxLength="12" v-model="queryParam.startAmount" allow-clear/> -
                  <a-input-number style="width: 45%" :maxLength="12" v-model="queryParam.endAmount"  allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="是否开票" prop="isInvoice">
                  <a-select placeholder="请选择是否开票" style="width: 100%" v-model="queryParam.isInvoice" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="下单开始时间" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"  @change="startTimeChange"  format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="下单结束时间" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime" @change="endTimeChange" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="投放开始时间" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.advertStartTime"  @change="advertStartTimeChange"  format="YYYY-MM-DD" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="投放结束时间" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.advertEndTime" @change="advertEndTimeChange" format="YYYY-MM-DD" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane key="" tab="全部订单"/>
        <a-tab-pane key="10" tab="待审核"/>
        <a-tab-pane v-for="pane in this.customDict.OrderAdvertStatusEnum" :key="pane.type" :tab="pane.name">
        </a-tab-pane>
      </a-tabs>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:advert:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <pay-form
        ref="payForm"
        @ok="getList"
      />
      <audit-form
        ref="auditForm"
        @ok="getList"
      />
      <detail-form
        ref="detailForm"
        @ok="getList"
      />
      <remark-form
        ref="remarkFrom"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        @change="handleSortChange"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="orderStatus" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.OrderAdvertStatusEnum" :value="record.orderStatus"/>
        </span>
        <span slot="operation" slot-scope="text, record" >
            <a-divider type="vertical"/>
            <a @click="$refs.detailForm.handleDetail(record.id)">
              <a-icon type="eye" />详情
            </a>
           <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="double-right" />
              更多
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="$refs.remarkFrom.handleRemark(record)" >
                  <a-icon type="edit" />
                  备注
                </a>
              </a-menu-item>
              <a-menu-item v-hasPermi="['order:advert:pay']" v-if="record.orderStatus===4010">
                <a @click="$refs.payForm.handlePay(record, undefined)"  v-hasPermi="['order:advert:pay']">
                  <a-icon type="edit" />
                  确认支付
                </a>
              </a-menu-item>
              <a-menu-item v-hasPermi="['order:advert:cancel']" v-if="record.auditStatus==null && (record.orderStatus===4010 || record.orderStatus===4020 )">
                <a @click="handleCancel(record)"  v-hasPermi="['order:advert:cancel']">
                  <a-icon type="edit" />
                  取消订单
                </a>
              </a-menu-item>
              <a-menu-item v-hasPermi="['order:advert:audit']" v-if="record.auditStatus===10 && record.orderStatus !== 4030 && record.orderStatus !== 4040" >
                <a @click="$refs.auditForm.handleAudit(record, undefined)" v-hasPermi="['order:advert:audit']">
                  <a-icon type="edit" />
                  审核
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageAdvert,listAdvert,cancelAdvert, delAdvert } from '@/api/order/advert'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import AuditForm from './modules/AuditForm'
import detailForm from './modules/detailForm'
import PayForm from './modules/PayForm'
import RemarkForm from "@/views/order/order/modules/RemarkForm";

import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Advert',
  components: {
    CustomDictTag,
    PayForm,
    detailForm,
    AuditForm,
    CreateForm,
    RemarkForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      activeKey: null,
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewVisible: false,
      previewAvatar: '',
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: null,
        advertId: null,
        auditStatus: null,
        spec: null,
        payType:null,
        signName: null,
        brandName: null,
        startAmount: null,
        endAmount: null,
        contactName: null,
        contactPhone: null,
        createBeginTime: null,
        createEndTime: null,
        advertStartTime: null,
        advertEndTime: null,
        price: null,
        count: null,
        payTime: null,
        outTradeNo: null,
        isInvoice: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '订单编号',
          dataIndex: 'id',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatusName',
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'auditStatusName',
          align: 'center'
        },
        {
          title: '广告名称',
          dataIndex: 'advertName',
          align: 'center'
        },
        {
          title: '规格尺寸',
          dataIndex: 'spec',
          align: 'center'
        },
        {
          title: '投放套餐',
          dataIndex: 'timePackage',
          align: 'center'
        },
        {
          title: '签约公司',
          dataIndex: 'signName',
          align: 'center'
        },
        {
          title: '投放品牌',
          dataIndex: 'brandName',
          align: 'center'
        },
        {
          title: '联系人',
          dataIndex: 'contactName',
          align: 'center'
        },
        {
          title: '联系电话',
          dataIndex: 'contactPhone',
          align: 'center'
        },
        {
          title: '投放开始时间',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'startTime',
          align: 'center'
        },
        {
          title: '投放结束时间',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'endTime',
          align: 'center'
        },
        {
          title: '应付款 ',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'payAmount',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询订单-广告订单列表 */
    getList () {
      this.loading = true
     pageAdvert(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    //切换tab
    changeTab(index) {
      if (index == null) {
        index = undefined;
      }
      //待审核
      if (index == 10) {
        this.queryParam.auditStatus = index;
      } else {
        this.queryParam.orderStatus = index;
        this.queryParam.auditStatus = null;
      }
      this.getList();
    },
    startTimeChange(date, dateString){
      this.queryParam.createBeginTime = dateString
    },
    endTimeChange(date, dateString){
      this.queryParam.createEndTime = dateString
    },
    advertStartTimeChange(date, dateString){
      this.queryParam.advertStartTime = dateString
    },
    advertEndTimeChange(date, dateString){
      this.queryParam.advertEndTime = dateString
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        advertId: undefined,
        advertSnapshot: undefined,
        spec: undefined,
        timePackage: undefined,
        signName: undefined,
        brandName: undefined,
        contactName: undefined,
        contactPhone: undefined,
        startTime: undefined,
        endTime: undefined,
        price: undefined,
        count: undefined,
        orderAmount: undefined,
        payAmount: undefined,
        payTime: undefined,
        outTradeNo: undefined,
        isInvoice: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleCancel (record) {
      var that = this
      this.$confirm({
        title: '是否确认取消?',
        onOk () {
          return cancelAdvert({id:record.id})
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '取消成功',
                3
              )
            })
        },
        onCancel () {}
      })
      this.getList();
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids= []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delAdvert(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('order/advert/export', {
            ...that.queryParam
          }, `订单-广告订单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
